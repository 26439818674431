<template>
  <div class="row m-0">
    <div class="col-12 p-0 mt-3">
      <div class="row m-0 justify-content-center">
        <div class="col-auto text-center page_title p-0">
          <div class="page_title">
            <span>{{title}}</span>
          </div>
          <div class="page_title_underline w-100 mt-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page Title',
  props: ['title']
}
</script>

<style scoped>
.page_title {
  font-size: 14px;
  color: var(--gold-color);
  font-family: "quicksand_bold", Sans-serif;
  font-weight: bold;
  letter-spacing: 0.4rem;
  padding-right: 0.5rem;
  padding-left: 1rem;
  text-transform: uppercase;
}
.page_title_underline {
  border-bottom: 2px solid var(--gold-color);
}
@media (min-width: 992px) {
  .page_title {
    font-size: 21px;
  }
}
</style>
